import colors from '../colors';

const theme = {
  ...colors,
  // containers
  background: '#111',
  onBackground: '#eee',
  surface: '#191919',
  onSurface: '#bbb',
  degrade: 'linear-gradient(90deg, rgba(17,17,17,1) 0%, rgba(51,51,51,1) 100%)',
  // texts
  placeholder: '#555',
  textSection: colors.white,
  textLabel: '#ddd',
  text: '#ccc',
  shadow: 'rgba(255,255,255,0.2)',
  // tabBar
  activeTabBar: colors.white,
  inactiveTabBar: colors.light,
};
export default theme;
