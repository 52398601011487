/* eslint-disable import/no-anonymous-default-export */
import colorsDefault from './default';
import colorsDark from './dark';

const themeDefault = {
  title: 'default',
  colors: colorsDefault,
};

const themeDark = {
  title: 'dark',
  colors: colorsDark,
}

export default {
  default: themeDefault,
  dark: themeDark,
};
