import React, { useEffect } from "react"
import PropTypes from "prop-types"
// import { useStaticQuery, graphql } from "gatsby"
import "./layout.css"
import { Container, Text, Footer } from "./atomics"
import Header from "./Header"
import "../assets/fonts/Questrial-Regular.ttf"
import "../assets/fonts/Poppins/Poppins-Light.ttf"
import "../assets/fonts/Poppins/Poppins-Bold.ttf"
import "../assets/fonts/Poppins/Poppins-Regular.ttf"
import { URL_SITE_ROGERD } from "../constants"
import { Link } from "gatsby"
import { useState } from "react"
import themes from "../styles/themes"
import { ThemeProvider } from "styled-components"
import colors from "../colors"

const Layout = ({
  children,
  title = "FLYNOW",
  logo,
  hideHeader = false,
  hideFooter = false,
  forceTheme,
  primaryColor=colors.primary
}) => {
  const [theme, setTheme] = useState(forceTheme || "default")
  useEffect(() => {
    if(!forceTheme){
      setTheme(localStorage.getItem("theme") || "default")
    }
  }, [])
  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `)
  return (
    <ThemeProvider theme={theme === "dark" ? themes.dark : themes.default}>
      {!hideHeader && (
        <>
          <Header title={title} theme={theme} setTheme={setTheme} logo={logo} primaryColor={primaryColor} />
          <div style={{ marginTop: "3rem" }} />
        </>
      )}
      <Container>{children}</Container>
      {!hideFooter && (
        <Footer
          style={
            // title.toUpperCase().includes("FLYNOW - FINANÇAS")
            //   ? { background: `#29CD83` }
            //   : title === "FLYNOW - FRASES"
            //   ? { background: `#F1C40F` }
            //   : {}

              {background: primaryColor}
          }
        >
          <Link to={URL_SITE_ROGERD} target="_blank" rel="noopener noreferrer">
            <Text style={{ fontSize: 17, color: "#fff" }}>
              Desenvolvido por © Rogerd Ribeiro
            </Text>
          </Link>
        </Footer>
      )}
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
