import React from 'react';
import { LogoContainer } from './styles';
import { TextLogo } from '../atomics';
import { useTheme } from 'styled-components';
import { useParams } from '../../hooks';

function Logo({title,logo,primaryColor}) {
  const theme = useTheme()
  const { query } = useParams()

  return (
    <LogoContainer href={"/"+query}>
      <img style={{width:45}} src={logo||require('../../assets/images/logo.png')} alt="flynow-logo" className="logo" />
      <TextLogo color={primaryColor}>{title}</TextLogo>
    </LogoContainer>
  )
}

export default Logo;