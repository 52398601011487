import colors from '../colors';


export default {
  ...colors,
  // containers
  background: '#fff',
  onBackground: '#333',
  surface: '#f6f6f6',
  onSurface: '#777',
  // texts
  placeholder: colors.lighter,
  textSection: colors.primary,
  textLabel: colors.dark,
  text: colors.regular,
  shadow: 'rgba(0,0,0,0.27)',

  activeTabBar: colors.white,
  inactiveTabBar: '#aaa',
};
