import styled from 'styled-components';
import colors from '../../colors';

export const HeaderContainer = styled.header`
  display:flex;
  flex:1;
	position: fixed; /* Set the navbar to fixed position */
	top: 0; /* Position the navbar at the top of the page */
	min-width: 100%;
  justify-content: space-between;
  align-items:center;
  padding: 10px 10%;
  /* border-bottom: 1px solid  ${({theme})=> theme.colors.surface}; */
  background:${({theme})=> theme.colors.background};
  -webkit-box-shadow: 0px 0px 10px 1px ${({theme})=> theme.colors.shadow}; 
  box-shadow: 0px 0px 10px 1px ${({theme})=> theme.colors.shadow};
  .icon-menu{
    display:none; 
  }
  .header-logo{
    display:flex;
    justify-content:space-between;
    align-items:center;
  }
  @media (max-width: 850px) {
    flex-direction:column;
    align-items:flex-start;
    .icon-menu{
      display:block;
      height: 23px;
    }
    .logo{
      height: 45px;
    }
    .header-logo{
      width:100%;
    }
    padding-right: 5%;
    padding-left: 5%;
	}
`;
export const Menu = styled.div`
  display:flex;
  justify-content: space-between;
  align-items: center;
  color: ${colors.textStrong};
  .item-menu{
    padding: 0 10px;
    &:hover{
      color: ${colors.primary};
      cursor:pointer;
    }
  }
  @media (max-width: 850px) {
    flex-direction:column;
    margin-top:1rem;
    display: ${({menuOpen})=> menuOpen ? 'block': 'none'};
    .item-menu{
      padding: 5px 0;
    }
  }
`;

