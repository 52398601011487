import { useEffect, useState } from "react"
import { getQueryParam } from "../utils/query"

export const useLanguage = defaultLanguage => {
  const [language, setLanguage] = useState(defaultLanguage || "en")

  useEffect(() => {
    const [, p] = document.location?.search?.split("ln=")
    if (p) {
      const [ln] = p?.split("&")
      if (ln) {
        setLanguage(ln)
      }
    }
  }, [])

  return { language, setLanguage }
}

export const useParams = () => {
  const [query, setQuery] = useState("")
  const [url, setUrl] = useState("")
  const [params, setParams] = useState({})

  useEffect(() => {
    const search = document.location?.search
    setUrl(document.location?.href)
    if (search) {
      setQuery(search)
      const currentParams = {...params}
      search.split('&').forEach(param => {
        const [key, value] = param.split('=');
        currentParams[key.replace('?','')] = decodeURIComponent(value);
      });
      setParams(currentParams)
    }
  }, [])

  return { query, url,params }
}
