export default {
  white: '#fff',
  lighter: '#ddd',
  light: '#999',
  regular: '#666',
  dark: '#333',
  darker: '#222',
  black: '#000',

  primary: '#AA20FF',
  degrade: 'linear-gradient(135deg, rgba(121,101,202,1) 0%, rgba(121,101,202,1) 30%, rgba(170,32,255,1) 100%)',

  // degrade: [ '#7965CA', '#AA00CC' ],
  // degrade: [ '#ef5350', '#ee6350' ],
  // degrade: [ '#56ccf2', '#96ccf2' ],
  // degrade: [ '#03C060', '#00a060' ],

  darkTransparent: 'rgba(0, 0, 0, 0.8)',
  transparent: 'transparent',

  premium: '#F0BA28',
  active: '#03C060',

  green: '#6FCF97',
  red: '#EB5757',
  blue: '#56CCF2',
  yellow: '#F2994A',
};
